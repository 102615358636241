import cn from "classnames";

import { SearchClientByPhone, SearchWithSuggestions } from "@/blocks/index";
import { Checkbox, DadataField, Datepicker, InputField, Select, Tabs, SelectWithSearch, RadioGroup } from "@/ui/index";

import styles from "./inputsSpreader.module.scss";

const InputsSpreader = ({
  type,
  value,
  options,
  defaultOption,
  onChange,
  className,
  setSelectedClientByPhone,
  setSelectedClientByEmail,
  setSelectedClientByName,
  personPosition,
  formId,
  clientOptions,
  selectedClient,
  selectedLegalForm,
  legalForms,
  isEdit,
  setSearchValue,
  member,
  searchValue,
  withLink,
  setLinkedPerson,
  changeSearchSelect,
  onSelectClose,
  onScroll,
  ...other
}: InputsSpreader) => {
  switch (type) {
    case "date":
      return (
        <Datepicker
          {...other}
          className={className && styles[className]}
          type={type}
          value={value}
          onChange={onChange as InputOnChange}
        />
      );
    case "select":
      if (clientOptions && clientOptions.length > 0 && other.field === "linkedPerson") {
        return (
          <Select
            {...other}
            className={className && styles[className]}
            value={value}
            field={other.field}
            options={clientOptions}
            label={other.label}
            defaultValue={selectedClient ? selectedClient : clientOptions[0]}
            onSelect={onChange as InputOnChange}
            size="big"
          />
        );
      }
      if (legalForms && legalForms.length > 0 && other.field === "legalForm") {
        return (
          <Select
            {...other}
            className={className && styles[className]}
            value={value}
            field={other.field}
            options={legalForms}
            label={other.label}
            defaultValue={selectedLegalForm ? selectedLegalForm : legalForms[0]}
            onSelect={onChange as InputOnChange}
            size="big"
          />
        );
      }
      return (
        <Select
          {...other}
          className={className && styles[className]}
          value={value}
          field={other.field}
          options={options ?? []}
          defaultValue={defaultOption ?? null}
          onSelect={onChange as InputOnChange}
          onScroll={onScroll}
        />
      );
    case "selectsearch":
      if (legalForms && legalForms.length > 0 && other.field === "legalForm" && changeSearchSelect && onSelectClose) {
        return (
          <SelectWithSearch
            {...other}
            className={className && styles[className]}
            value={value}
            field={other.field}
            options={legalForms}
            label={other.label} // @ts-ignore
            defaultValue={selectedLegalForm}
            onSelect={onChange as InputOnChange}
            changeSearchSelect={changeSearchSelect}
            onClose={onSelectClose}
            size="big"
            onScroll={onScroll}
          />
        );
      }
      return <></>;
    case "checkbox":
      return (
        <Checkbox
          {...other}
          isChecked={Boolean(value)}
          onChange={onChange as CheckboxOnChange}
          className={className && styles[className]}
        />
      );
    case "radio":
      return (
        <div className={styles.formTabs}>
          <Tabs buttons={options as InputData[]} onChange={onChange as InputOnChange} field={other.field} formId={formId} />
        </div>
      );

    case "radioGroup":
      return (
        <RadioGroup
          options={options as Option[]}
          onChange={onChange as InputOnChange}
          field={other.field}
          id={other.id} // @ts-ignore
          defaultValue={other.defaultValue}
        />
      );
    case "dadata":
      return (
        <DadataField
          {...other}
          type={type}
          value={value}
          onChange={onChange as InputOnChange}
          className={className && styles[className]}
        />
      );
    case "search":
      if (setSearchValue) {
        return (
          <SearchWithSuggestions
            className={className && styles[className]}
            setLinkedPersonId={setSearchValue}
            setLinkedPerson={setLinkedPerson}
            isEdit={isEdit}
            editMember={member}
            linkedPersonId={searchValue}
            label={other.label || ""}
            placeholder={other.placeholder || ""}
            ageLimit={other.ageLimit}
            invalid={other.invalid}
            helperText={other.helperText}
          />
        );
      } else {
        return null;
      }
    case "tel":
    case "email":
      if (other.field === "phoneNumber" && setSelectedClientByPhone) {
        return (
          <SearchClientByPhone
            setSelectedClient={setSelectedClientByPhone}
            personPosition={personPosition}
            value={value}
            onChange={onChange as InputOnChange}
            {...other}
            className={cn(styles.phoneSearch, className && styles[className])}
            withLink={withLink}
          />
        );
      } else if (other.field === "email" && setSelectedClientByEmail) {
        return (
          <SearchClientByPhone
            setSelectedClient={setSelectedClientByEmail}
            personPosition={personPosition}
            value={value}
            byField={other.field}
            onChange={onChange as InputOnChange}
            {...other}
            className={cn(styles.phoneSearch, className && styles[className])}
            withLink={withLink}
          />
        );
      }
      return (
        <InputField
          {...other}
          type={type}
          value={value}
          onChange={onChange as InputOnChange}
          className={className && styles[className]}
        />
      );

    case "member":
      if (setSelectedClientByName) {
        return (
          <SearchClientByPhone
            personPosition={personPosition}
            value={value}
            setSelectedClient={setSelectedClientByName}
            onChange={onChange as InputOnChange}
            {...other}
            className={cn(styles.phoneSearch, className && styles[className])}
          />
        );
      }
      return (
        <InputField
          {...other}
          type="text"
          value={value}
          onChange={onChange as InputOnChange}
          className={className && styles[className]}
        />
      );

    default:
      return (
        <InputField
          {...other}
          type={type}
          value={value}
          onChange={onChange as InputOnChange}
          className={className && styles[className]}
        />
      );
  }
};

export default InputsSpreader;
