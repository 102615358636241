export const phoneMask = "+7 999 999 99 99";
export const AdminId = 4;
export const SystemAdminId = 5;
export const fieldsForCheckRussianLetters = [
  "district_full_name",
  "district_short_name",
  "chamber_name",
  "districtFullName",
  "districtShortName",
  "chamberName",
  "fullName",
  "address",
  "registrationAddress",
  "companyName",
  "companyAddress",
  "companyFiasAddress",
];
export const dadataToken = "3ece1bc695153e5f4ae1f507c805343369199637";
export const watsappDomen = "https://wa.me";
export const GOOGLE_CALENDAR_LINK = "https://calendar.google.com/calendar/";
export const PAGE_LIMIT = 10;

export const DATE_REGEX = /(\d{2}).(\d{2}).(\d{4})/;
