import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { SwitchTransition, CSSTransition } from "react-transition-group";

import { ApplicationList, ErrorResult } from "@/blocks/index";
import KanbanList from "@/blocks/KanbanList/KanbanList";
import { useActions } from "@/store/hooks";
import { useFetchApplicationStatusesQuery } from "@/store/services/action.query";
import { useFetchApplicationsQuery, useGetApplicationActionsQuery } from "@/store/services/application.query";
import { Loader } from "@/ui/index";

import styles from "./Applications.module.scss";

const Applications = () => {
  const { officeId, id } = useParams();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const [searchParams, setSearchParams] = useSearchParams();
  const [pagination, setPagination] = useState<PaginationProps>({ count: 0, page: 1 });
  const [currentPage, setCurrentPage] = useState<number>(parseInt(queryParams.get("page")!) || 1);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [isError, setIsError] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentTab, setCurrentTab] = useState<string>("list");
  const title = "Реестр заявок";
  const [applications, setApplications] = useState<Model.ApplicationCard[]>([]);
  const [actions, setActions] = useState<Option[]>([
    { name: "Нотариальное действие", value: "" },
    { name: "Все действия", value: "undefined" },
  ]);
  const [statuses, setStatuses] = useState<Option[]>([
    { name: "Статус", value: "" },
    { name: "Все статусы", value: "undefined" },
  ]);

  const fetchedActions = useGetApplicationActionsQuery(Number(officeId) || Number(id));
  const fetchedApplications = useFetchApplicationsQuery({
    officeId: Number(officeId) || Number(id),
    query: `${search}${searchQuery.length > 0 ? `${search.length > 0 ? "&" : "?"}search=${searchQuery}` : ""}`,
  });
  const fetchStatuses = useFetchApplicationStatusesQuery(Number(officeId) || Number(id));
  const { setCount } = useActions();

  useEffect(() => {
    if (fetchedApplications.isSuccess && fetchedApplications.data) {
      const fetch = fetchedApplications.data as Response.ApplicationsResponse;
      setApplications(fetch?.results);
      setPagination({ count: fetch.count, page: currentPage, previous: fetch.previous, next: fetch.next });
      setCount(fetch.count);

      if (isLoading) {
        setTimeout(() => {
          setIsLoading(false);
        }, 250);
      }
    }

    if (fetchedApplications.error) {
      if ((fetchedApplications.error as ErrorObject[])[0]?.code === "not_found") {
        setIsError(404);
      } else if ((fetchedApplications.error as ErrorObject[])[0]?.code === "permission_denied") {
        setIsError(403);
      } else {
        setIsError((fetchedApplications.error as ErrorResponse).status || 500);
      }
      if (isLoading) {
        setTimeout(() => {
          setIsLoading(false);
        }, 250);
      }
    } else {
      setIsError(null);
    }
  }, [fetchedApplications]);

  useEffect(() => {
    if (fetchedActions.isSuccess && fetchedActions.data) {
      const actions = (fetchedActions.data as Model.Action[]).map((el) => ({ name: el.actionName, value: Number(el.id) }));
      setActions((prev) => [...prev, ...actions]);
    }
  }, [fetchedActions]);

  useEffect(() => {
    if (fetchStatuses.isSuccess && fetchStatuses.data) {
      const statuses = (fetchStatuses.data as Option[]).map(({ id, name }) => ({ name, value: Number(id) }));
      setStatuses((prev) => [...prev, ...statuses]);
    }
  }, [fetchStatuses]);

  useEffect(() => {
    if (currentPage > 1) {
      searchParams.set("page", "1");
      setSearchParams(searchParams);
    }
  }, [searchQuery]);

  useEffect(() => {
    setCurrentPage(parseInt(queryParams.get("page")!) || 1);
  }, [search]);

  return (
    <main>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className={`${styles.applications} container`}>
        <div className={styles.head}>
          <h2>{title}</h2>
        </div>
        <SwitchTransition>
          <CSSTransition key={isLoading ? "loader" : "content"} timeout={250} classNames="fade">
            {isLoading ? (
              <div className={styles.loaderWrap}>
                <Loader />
              </div>
            ) : (
              <SwitchTransition>
                <CSSTransition key={isError ? "notFound" : "content"} timeout={250} classNames="fade">
                  {isError ? (
                    <ErrorResult code={isError} />
                  ) : (
                    <>
                      {currentTab === "list" ? (
                        <ApplicationList
                          actions={actions}
                          statuses={statuses}
                          setSearchQuery={setSearchQuery}
                          loading={fetchedApplications.isFetching}
                          paginationProps={{
                            ...pagination,
                            page: currentPage,
                          }}
                          applications={applications}
                        />
                      ) : (
                        <KanbanList actions={actions} />
                      )}
                    </>
                  )}
                </CSSTransition>
              </SwitchTransition>
            )}
          </CSSTransition>
        </SwitchTransition>
      </div>
    </main>
  );
};

export default Applications;
