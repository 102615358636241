import { phoneMask } from "@/shared/constants";

export const infoInputsData = [
  {
    id: 1,
    field: "districtFullName",
    type: "text",
    label: "Округ нотариуса",
    placeholder: "Например «Волгодонский (городской) нотариальный округ»",
    required: true,
    value: "",
    hasLabelMark: true,
  },
  {
    id: 2,
    field: "districtShortName",
    type: "text",
    label: "Округ кратко",
    placeholder: "Например «Волгодонский (городской) нотариальный округ»",
    required: true,
    value: "",
    hasLabelMark: true,
  },
  {
    id: 3,
    field: "chamberName",
    type: "text",
    label: "Наименование конторы",
    placeholder: "Например «НОТАРИАЛЬНАЯ конторы»",
    required: true,
    value: "",
    hasLabelMark: true,
  },
  {
    id: 4,
    field: "fullName",
    type: "text",
    label: "ФИО нотариуса",
    placeholder: "ФИО нотариуса",
    required: true,
    value: "",
    hasLabelMark: true,
  },
  {
    id: 5,
    field: "inn",
    type: "text",
    label: "ИНН",
    placeholder: "12345678912",
    required: true,
    value: "",
    hasLabelMark: true,
    minLength: 9,
    maxLength: 12,
    pattern: "\\d{9,12}",
  },
  {
    id: 6,
    field: "license",
    type: "text",
    label: "Лицензия Нотариуса",
    placeholder: "Лицензия Нотариуса",
    required: true,
    value: "",
    hasLabelMark: true,
  },
  {
    id: 7,
    field: "phoneNumber",
    type: "tel",
    label: "Телефон",
    placeholder: phoneMask,
    required: true,
    value: "",
    hasLabelMark: true,
    mask: phoneMask,
  },
  {
    id: 8,
    field: "email",
    type: "email",
    label: "Электронная почта",
    placeholder: "Email",
    required: true,
    value: "",
    hasLabelMark: true,
  },
];
export const addressInputs = [
  {
    id: 1,
    field: "region",
    type: "text",
    placeholder: "Например «Московская область»",
    required: true,
    value: "",
    className: "col12",
    hasLabelMark: true,
  },
  {
    id: 2,
    field: "city",
    type: "text",
    placeholder: "Например «Москва»",
    required: true,
    value: "",
    className: "col12",
    hasLabelMark: true,
  },
  {
    id: 3,
    field: "street",
    type: "text",
    placeholder: "Например «Большой Афанасьевский переулок»",
    required: true,
    value: "",
    className: "col12",
    hasLabelMark: true,
  },
  {
    id: 4,
    field: "house",
    type: "text",
    placeholder: "Дом",
    required: true,
    value: "",
    className: "col4",
    hasLabelMark: true,
  },
  {
    id: 5,
    field: "office",
    type: "text",
    placeholder: "Офис",
    required: true,
    value: "",
    className: "col4",
    hasLabelMark: true,
  },
  {
    id: 6,
    field: "postCode",
    type: "text",
    placeholder: "Индекс",
    minLength: 6,
    maxLength: 6,
    required: true,
    value: "",
    className: "col4",
    hasLabelMark: true,
    pattern: "[0-9]{5,10}",
  },
];

export const typeOfAddress: addressType[] = [
  { id: 1, addressType: "notaryOffice", label: "адрес нотариальной конторы", address: addressInputs },
  { id: 2, addressType: "notary", label: "адрес нотариуса", address: addressInputs },
  { id: 3, addressType: "fias", label: "адрес нотариуса (ФИАС)", address: addressInputs },
];

export const scheduleInputsData = [
  {
    id: 1,
    day: "Понедельник",
    field: "monday",
    available: true,
    timeSlots: [
      { id: 1, value: null, field: "startedAt", required: true },
      { id: 2, value: null, field: "finishedAt", required: true },
    ],
  },
  {
    id: 2,
    day: "Вторник",
    field: "tuesday",
    available: true,
    timeSlots: [
      { id: 1, value: null, field: "startedAt", required: true },
      { id: 2, value: null, field: "finishedAt", required: true },
    ],
  },
  {
    id: 3,
    day: "Среда",
    field: "wednesday",
    available: true,
    timeSlots: [
      { id: 1, value: null, field: "startedAt", required: true },
      { id: 2, value: null, field: "finishedAt", required: true },
    ],
  },
  {
    id: 4,
    day: "Четверг",
    field: "thirsday",
    available: true,
    timeSlots: [
      { id: 1, value: null, field: "startedAt", required: true },
      { id: 2, value: null, field: "finishedAt", required: true },
    ],
  },
  {
    id: 5,
    day: "Пятница",
    field: "friday",
    available: true,
    timeSlots: [
      { id: 1, value: null, field: "startedAt", required: true },
      { id: 2, value: null, field: "finishedAt", required: true },
    ],
  },
  {
    id: 6,
    day: "Суббота",
    field: "saturday",
    available: false,
    timeSlots: [
      { id: 1, value: null, field: "startedAt", required: true },
      { id: 2, value: null, field: "finishedAt", required: true },
    ],
  },
  {
    id: 7,
    day: "Воскресенье",
    field: "sunday",
    available: false,
    timeSlots: [
      { id: 1, value: null, field: "startedAt", required: true },
      { id: 2, value: null, field: "finishedAt", required: true },
    ],
  },
];
