import { priorityMockdata } from "@/blocks/ClientCard/mockData";
import { phoneMask } from "@/shared/constants";

export const types = [
  { id: 1, name: "Простой", value: 1 },
  { id: 2, name: "Средний", value: 2 },
  { id: 3, name: "Сложный", value: 3 },
];

export const applocation = [
  {
    id: 1,
    field: "action",
    type: "select",
    label: "Нотариальное действие",
    placeholder: "Нотариальное действие",
    required: true,
    value: "",
    hasLabelMark: true,
    className: "col12",
    size: "big",
  },
  {
    id: 2,
    field: "type",
    type: "select",
    label: "Критерий сложности",
    placeholder: "Простой",
    className: "col6",
    size: "big",
    required: true,
    hasLabelMark: true,
    options: types,
    defaultOption: types[0],
    value: types[0].value,
  },
  {
    id: 3,
    field: "priority",
    type: "select",
    label: "Приоритет",
    placeholder: "Низкий",
    required: true,
    size: "big",
    hasLabelMark: true,
    className: "col6",
    options: priorityMockdata,
    defaultOption: priorityMockdata[0],
    value: priorityMockdata[0].value,
  },
  {
    id: 4,
    field: "name",
    type: "member",
    label: "Клиент",
    placeholder: "ФИО или  название компании",
    required: true,
    value: "",
    hasLabelMark: true,
    className: "col12",
  },
  {
    id: 5,
    field: "phoneNumber",
    type: "tel",
    label: "Телефон клиента",
    placeholder: phoneMask,
    required: true,
    value: "",
    className: "col12",
    hasLabelMark: true,
    mask: phoneMask,
    withNameAndField: true,
  },
  {
    id: 6,
    field: "email",
    type: "email",
    label: "Электронная почта клиента",
    placeholder: "Email",
    required: true,
    value: "",
    className: "col12",
    hasLabelMark: true,
    withNameAndField: true,
  },
];

export const ComplexityData = [
  { name: "", value: "" },
  { name: "", value: "" },
  { name: "", value: "" },
];
