import { useEffect, useState } from "react";

import { EditIcon } from "@/assets/icons";
import { List } from "@/blocks/List";
import styles from "@/blocks/OfficeDetailInfo/OfficeDetailInfo.module.scss";
import { formatPhoneNumber, formatTime } from "@/helpers/utils";
import { useAppSelector, useUserPermissions } from "@/store/hooks";
import { DaysOfWeek } from "@/types/enums";
import { Button } from "@/ui/index";

const OfficeDetailInfo = () => {
  const { office } = useAppSelector((state) => state.office);
  const [infoData, setInfoData] = useState<Blocks.ListItem[]>();
  const [schedule, setSchedule] = useState<Blocks.ListItem[]>([]);

  const pesmissions = useUserPermissions();

  useEffect(() => {
    if (office !== undefined) {
      const { notary, fias, notaryOffice } = office.address || {};
      const info: Blocks.ListItem[] = [
        { id: 1, icon: "phone", text: office.phoneNumber && formatPhoneNumber(office.phoneNumber) },
        { id: 2, icon: "email", text: office.email },
        { id: 3, icon: "user", text: office.fullName, subtext: "ФИО нотариуса" },
        { id: 4, icon: "bag", text: office.inn, subtext: "ИНН нотариуса" },
        { id: 5, icon: "bag", text: office.license, subtext: "Лицензия" },
      ];
      notary &&
        info.push({
          id: 6,
          icon: "location",
          subtext: "Нотариальная контора",
          text: `г.${notary.city}, ул.${notary.street}, д.${notary.house}, офис ${notary.office}`,
        });
      notaryOffice &&
        info.push({
          id: 7,
          icon: "location",
          subtext: "Адрес нотариуса",
          text: `г.${notaryOffice.city}, ул.${notaryOffice.street}, д.${notaryOffice.house}, офис ${notaryOffice.office}`,
        }),
        fias &&
          info.push({
            id: 8,
            icon: "location",
            subtext: "Нотариус (ФИАС)",
            text: `г.${fias.city}, ул.${fias.street}, д.${fias.house}, офис ${fias.office}`,
          }),
        setInfoData(info);

      const scheduleData: Blocks.ListItem[] = (office?.schedule || [])
        .filter((el) => el.isWork)
        .map((el, idx) => ({
          id: idx,
          icon: idx === 0 ? "time" : "hasIcon",
          text: `${formatTime(el.startedAt.slice(0))} - ${formatTime(el.finishedAt.slice(0))}`,
          subtext: DaysOfWeek[el.day as keyof typeof DaysOfWeek],
        }));

      setSchedule(scheduleData);
    }
  }, [office]);

  return (
    <div className={styles.officeDetailInfo}>
      <div className={styles.content}>
        <div className={styles.row}>
          <h4>Общая информация</h4>
          {pesmissions?.customUpdateNotary && office && office.isActive && (
            <Button variant="primary" size="small" href={`/offices/${office.id}/edit-office-info`}>
              <EditIcon />
              Редактировать
            </Button>
          )}
        </div>
        <List title="Общая информация" list={infoData} />
        <List title="Режим работы" list={schedule} colClassName={styles.time} />
      </div>
    </div>
  );
};

export default OfficeDetailInfo;
