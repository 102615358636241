import { enqueueSnackbar } from "notistack";
import { Fragment, useEffect, useState, useLayoutEffect } from "react";
import ReactHtmlParser from "react-html-parser";
import { useParams } from "react-router-dom";
import { SwitchTransition, CSSTransition, TransitionGroup } from "react-transition-group";

import { AdminExitIcon } from "@/assets/icons";
import {
  ApplicationHead,
  HistoryBlock,
  ApplicationClientForm,
  CommentBlock,
  ErrorResult,
  DocumentsForActions,
  ApplicationDocumnets,
  ApplicationRights,
} from "@/blocks/index";
import { useUserPermissions } from "@/store/hooks";
import {
  useApplyApplicationMutation,
  useFetchApplicationClientsQuery,
  useFetchApplicationDetailQuery,
  useDeleteApplicationClientMutation,
} from "@/store/services/application.query";
import { Button, Clue } from "@/ui/index";

import styles from "./Application.module.scss";
import ApplicationSkeleton from "./ApplicationSkeleton ";

const Application = () => {
  const { officeId, applicationId } = useParams();
  const [loading, setLoading] = useState(true);
  const [isButtonLoading, setIsButtonLoading] = useState<{ [key: string]: boolean }>({});
  const [loadingClient, setLoadingClient] = useState(true);
  const [value, setValue] = useState("");
  const [isAcceptedAll, setIsAcceptedAll] = useState(false);
  const [clientForms, setClientForms] = useState<{ [key: number]: number | undefined }>({});
  const [applicationClients, setApplicationClients] = useState<Model.ApplicationClient[]>([]);
  const [applicationDetail, setApplicationDetail] = useState<Model.ApplicationDetail>({} as Model.ApplicationDetail);
  const [isError, setIsError] = useState<number | null>(null);
  const [isCheckedArchive, setIsCheckedArchive] = useState(false);
  const fetchApplicationClients = useFetchApplicationClientsQuery({
    officeId: String(officeId),
    applicationId: String(applicationId),
  });
  const fetchApplicationDetail = useFetchApplicationDetailQuery({
    officeId: String(officeId),
    applicationId: String(applicationId),
  });
  const [applyApplication, appliedAppliation] = useApplyApplicationMutation();
  const [deleteMember, deletedMember] = useDeleteApplicationClientMutation();
  const permissions = useUserPermissions();

  useLayoutEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    setIsError(permissions?.customDetailApplications ? null : 403);
  }, [permissions?.customDetailApplications]);

  useEffect(() => {
    if (appliedAppliation.isSuccess && appliedAppliation.data) {
      enqueueSnackbar("Запрос прошел успешно!", {
        variant: "success",
        autoHideDuration: 3000,
      });
      setValue("");
    }

    if (appliedAppliation.isError) {
      const message = appliedAppliation.error
        ? (appliedAppliation.error as Model.Error).message
        : "При отправке запроса произошла ошибка";

      enqueueSnackbar(<>{ReactHtmlParser(message)}</>, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  }, [appliedAppliation]);

  useEffect(() => {
    if (deletedMember.isSuccess && deletedMember.data) {
      enqueueSnackbar("Запрос прошел успешно!", {
        variant: "success",
        autoHideDuration: 3000,
      });
    }

    if (deletedMember.isError && deletedMember.error) {
      const message = deletedMember.error
        ? (deletedMember.error as Model.Error)?.message
        : "При отправке запроса произошла ошибка";

      enqueueSnackbar(<>{ReactHtmlParser(message)}</>, {
        variant: "error",
        autoHideDuration: 3000,
      });
    }
  }, [deletedMember]);

  useEffect(() => {
    if (fetchApplicationDetail.isSuccess && fetchApplicationDetail.data && !fetchApplicationDetail.isFetching) {
      setApplicationDetail(fetchApplicationDetail.data as Model.ApplicationDetail);
      setTimeout(() => {
        setLoading(false);
        if (fetchApplicationDetail.status === "fulfilled") {
          setIsButtonLoading({ ...isButtonLoading, [`${appliedAppliation.originalArgs?.data.historyType}`]: false });
        }
      }, 500);
    }
    if (fetchApplicationDetail.error && fetchApplicationDetail.isError && !fetchApplicationDetail.isFetching) {
      if ((fetchApplicationDetail.error as ErrorObject[])[0]?.code === "not_found") {
        setIsError(404);
      } else if ((fetchApplicationDetail.error as ErrorObject[])[0]?.code === "permission_denied") {
        setIsError(403);
      } else {
        setIsError((fetchApplicationDetail.error as ErrorResponse).status === 404 ? 404 : 500);
      }
      setTimeout(() => {
        setIsButtonLoading({ ...isButtonLoading, [`${appliedAppliation.originalArgs?.data.historyType}`]: false });
      }, 500);
    } else {
      setIsError(null);
    }
  }, [fetchApplicationDetail]);

  useEffect(() => {
    const isAccepted = applicationClients.some((el) => el.members.some((item) => item.isAccepted));
    setIsAcceptedAll(isAccepted);
  }, [applicationClients]);

  useEffect(() => {
    if (fetchApplicationClients.isSuccess && fetchApplicationClients.data) {
      setApplicationClients(fetchApplicationClients.data as Model.ApplicationClient[]);
      setTimeout(() => {
        setLoadingClient(false);
      }, 250);
    }
    if (fetchApplicationClients.error) {
      if ((fetchApplicationClients.error as ErrorObject[])[0]?.code === "not_found") {
        setIsError(404);
      } else if ((fetchApplicationClients.error as ErrorObject[])[0]?.code === "permission_denied") {
        setIsError(403);
      } else {
        setIsError((fetchApplicationClients.error as ErrorResponse).status || 500);
      }
      setTimeout(() => {
        setLoadingClient(false);
      }, 250);
    } else {
      setIsError(null);
    }
  }, [fetchApplicationClients]);

  const addClientForm = (clientIndex: number, clientId: number | string) => {
    const newFormId = Number(`${applicationClients[clientIndex].id}${clientId}`);
    setClientForms({
      ...clientForms,
      [clientIndex]: newFormId,
    });
  };

  const deleteHandle = (clientId: number | string) => {
    setClientForms({
      ...clientForms,
      [clientId]: undefined,
    });
  };

  const deleteMemberHandle = (memberId: number) => {
    if (!deletedMember.isLoading) {
      deleteMember({ officeId: Number(officeId), applicationId: Number(applicationId), memberId });
    }
  };

  const changeHandle: Textarea["onChange"] = ({ value }) => {
    setValue(String(value));
  };

  const appliedhandle = (historyType: number) => {
    if (!isButtonLoading?.[`${historyType}`]) {
      setIsButtonLoading({ ...isButtonLoading, [`${historyType}`]: true });
      const commentary = value.trim() === "" ? undefined : value;
      applyApplication({
        officeId: Number(officeId),
        applicationId: Number(applicationId),
        data: {
          commentary,
          historyType,
        },
      });
    }
  };

  return (
    <div className={` container ${styles.application}`}>
      <ApplicationHead application={applicationDetail} loading={loading} error={isError} />
      <SwitchTransition>
        <CSSTransition key={loadingClient ? "loader" : "content"} timeout={250} classNames="fade">
          {loadingClient ? (
            <ApplicationSkeleton />
          ) : (
            <SwitchTransition>
              <CSSTransition key={isError ? "error" : "content"} timeout={250} classNames="fade">
                {isError ? (
                  <div className={styles.errorWrap}>
                    <ErrorResult code={isError} />
                  </div>
                ) : (
                  <div className={styles.content}>
                    <div className={styles.inner}>
                      {applicationClients.map((client, clientIndex) => {
                        const clientId = clientIndex;
                        const formId = clientForms[clientId];
                        return (
                          <Fragment key={clientIndex}>
                            <TransitionGroup component={null}>
                              {client.members.map((member, memberIndex) => (
                                <CSSTransition key={memberIndex} classNames="fade" timeout={250}>
                                  <>
                                    <ApplicationClientForm
                                      deleteHandle={
                                        memberIndex !== 0 && applicationDetail.permissions?.deleteMember
                                          ? () => deleteMemberHandle(member.id)
                                          : undefined
                                      }
                                      member={member}
                                      formId={member.id}
                                      clientBlockText={
                                        memberIndex === 0 ? client.name : `${client.name} ${memberIndex + 1}`
                                      }
                                      memberTypeId={client.id}
                                      permissions={applicationDetail.permissions}
                                      status={applicationDetail.status?.applicationStatus}
                                    />
                                  </>
                                </CSSTransition>
                              ))}
                            </TransitionGroup>
                            <TransitionGroup component={null}>
                              {formId && (
                                <CSSTransition key={clientForms[clientId]} classNames="fade" timeout={250}>
                                  <ApplicationClientForm
                                    deleteHandle={() => deleteHandle(clientId)}
                                    formId={formId}
                                    clientBlockText={`${client.name} ${client.members.length + 1}`}
                                    memberTypeId={client.id}
                                    permissions={applicationDetail.permissions}
                                    status={applicationDetail.status?.applicationStatus}
                                  />
                                </CSSTransition>
                              )}
                            </TransitionGroup>

                            {applicationDetail.permissions?.addMember && client?.id && !formId && (
                              <button className={styles.addClientButton} onClick={() => addClientForm(clientId, client.id)}>
                                <AdminExitIcon /> Добавить клиента
                              </button>
                            )}

                            {applicationId && officeId && applicationDetail.action?.id === 1 && clientIndex === 0 && (
                              <ApplicationRights
                                applicationId={applicationId}
                                officeId={officeId}
                                isEdit={applicationDetail.permissions?.editMember}
                                data={{
                                  termApplication: applicationDetail.termApplication,
                                  transferPermission: applicationDetail.transferPermission,
                                }}
                              />
                            )}
                          </Fragment>
                        );
                      })}
                      {applicationId &&
                        officeId &&
                        (applicationDetail.permissions?.showDocumentsForSignature ||
                          applicationDetail.permissions?.documentsForSignature) && (
                          <DocumentsForActions
                            title="Документы на подписание"
                            applicationId={applicationId}
                            officeId={officeId}
                            isEdit={applicationDetail.permissions?.documentsForSignature}
                          />
                        )}
                      <CommentBlock value={value} onChange={changeHandle} />
                      {applicationId && officeId && (
                        <>
                          {(applicationDetail.permissions.showArchivedDocuments ||
                            applicationDetail.permissions?.loadArchivedDocuments) && (
                            <ApplicationDocumnets
                              applicationId={applicationId}
                              officeId={officeId}
                              name={applicationDetail.action.name}
                              withSwitch={!applicationDetail.permissions.showArchivedDocuments}
                              isArchive={!applicationDetail.permissions.showArchivedDocuments}
                              onChange={
                                applicationDetail.permissions.showArchivedDocuments ? undefined : setIsCheckedArchive
                              }
                            />
                          )}
                          {(applicationDetail.permissions.loadDocumentsRosReestr ||
                            applicationDetail.permissions.showDocumentsRosReestr) && (
                            <DocumentsForActions
                              title="Загрузите документы из Росреестр"
                              applicationId={applicationId}
                              officeId={officeId}
                              isEdit={applicationDetail.permissions.loadDocumentsRosReestr}
                            />
                          )}
                        </>
                      )}
                      <TransitionGroup component={null}>
                        {applicationDetail.message && (
                          <CSSTransition key="clue" classNames="fade" timeout={250}>
                            <Clue text={applicationDetail.message} />
                          </CSSTransition>
                        )}
                      </TransitionGroup>
                      <div className={styles.buttons}>
                        {applicationDetail?.buttons?.length > 0 &&
                          (!applicationDetail.permissions?.loadArchivedDocuments ||
                            (applicationDetail.permissions?.loadArchivedDocuments && isCheckedArchive)) &&
                          applicationDetail.buttons.map(({ value, name, variant }) => (
                            <Button
                              key={value}
                              variant={variant}
                              loader={isButtonLoading?.[`${value}`]}
                              disabled={
                                !isAcceptedAll ||
                                !applicationDetail.permissions?.changeStatus ||
                                isButtonLoading?.[`${value}`]
                              }
                              onClick={() => appliedhandle(value)}>
                              {name}
                            </Button>
                          ))}
                      </div>
                    </div>
                    <aside className={styles.history}>
                      <HistoryBlock />
                    </aside>
                  </div>
                )}
              </CSSTransition>
            </SwitchTransition>
          )}
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
};

export default Application;
