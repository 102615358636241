import { useState, useEffect } from "react";

import { GOOGLE_CALENDAR_LINK } from "@/shared/constants";
import { useChangeClientMeetMutation } from "@/store/services/application.query";
import { Checkbox, GoogleButton } from "@/ui/index";

import styles from "./ClientMeet.module.scss";

const ClientMeet = ({
  officeId,
  applicationId,
  memberId,
  checked,
}: {
  checked: boolean;
  officeId: string;
  applicationId: string;
  memberId: number;
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const [changeMeet] = useChangeClientMeetMutation();

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const changeHandle: (value: {
    field: string;
    value: string | number | string[] | boolean;
    id: number | string;
  }) => void = ({ value }) => {
    setIsChecked(Boolean(value));
    changeMeet({ officeId, applicationId, memberId });
  };

  return (
    <div className={styles.clientMeet}>
      <Checkbox
        isChecked={isChecked}
        onChange={changeHandle}
        id={memberId}
        field={"meet"}
        label="Необходима личная встреча"
      />
      {isChecked && (
        <a href={GOOGLE_CALENDAR_LINK} target="_blank" rel="noreferrer">
          <GoogleButton />
        </a>
      )}
    </div>
  );
};

export default ClientMeet;
