import type { FormEvent } from "react";

import cn from "classnames";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { SwitchTransition, CSSTransition } from "react-transition-group";

import { AddressForm, InfoForm, ScheduleForm, SuccessForm } from "@/blocks/CreateOfficeForms";
import { initialFormData } from "@/blocks/CreateOfficeForms/mockData";
import {
  addedErrorOnField,
  checkErrorStep,
  checkForRussianLetters,
  getValueFromArray,
  isAllDaysNotAvailable,
} from "@/helpers/index";
import { useMultistepForm } from "@/hooks/useMultistepForm";
import { useActions } from "@/store/hooks";
import { useCreateOfficeFullInfoMutation, useVerifyOfficeMutation } from "@/store/services/office.query";
import { Button, Logo, Steps } from "@/ui/index";

import styles from "./CreateOffice.module.scss";
import { stepsMenu } from "./mockData";

const CreateOffice = () => {
  const [disabled, setDisabled] = useState(false);
  const [showSuccessLetter, setShowSuccesLetter] = useState<boolean>(false);
  const [officeData, setOfficeData] = useState<CreateOfficeData>(initialFormData);
  const [menu] = useState(stepsMenu);

  const updateFields = (fields: Partial<CreateOfficeData>) => {
    setOfficeData((prev) => {
      return { ...prev, ...fields };
    });
  };

  const { currentStepIndex, step, isFirstStep, isLastStep, back, next, setCurrentStepIndex } = useMultistepForm([
    <InfoForm infoData={officeData.info} updateFields={updateFields} key="info" />,
    <AddressForm
      notary={officeData.notary}
      fias={officeData.fias}
      notaryOffice={officeData.notaryOffice}
      updateFields={updateFields}
      key="address"
    />,
    <ScheduleForm scheduleArray={officeData.schedule} updateFields={updateFields} key="schedule" />,
  ]);
  const [verifyOffice, verifyOfficeInfo] = useVerifyOfficeMutation();
  const [createOfficeFullInfo, createOfficeInfo] = useCreateOfficeFullInfoMutation();

  const { setSuccessEmail } = useActions();

  const { isError, newInputs } = checkForRussianLetters(officeData.info);
  const isAllDayNotAvailable = isAllDaysNotAvailable(officeData.schedule);

  useEffect(() => {
    setDisabled(isAllDayNotAvailable);
  }, [officeData]);

  useEffect(() => {
    const savedData = localStorage.getItem("createForm");
    if (savedData) {
      setOfficeData(JSON.parse(savedData) as CreateOfficeData);
    }
  }, []);

  useEffect(() => {
    if (verifyOfficeInfo.error || createOfficeInfo.error) {
      setOfficeData((prev) => ({
        ...prev,
        info: addedErrorOnField(
          (verifyOfficeInfo.error as ErrorObject[]) || (createOfficeInfo.error as ErrorObject[]),
          officeData.info,
        ),
        address: addedErrorOnField(createOfficeInfo.error as ErrorObject[], officeData.info),
      }));
      const errorStep = checkErrorStep(
        (createOfficeInfo.error as ErrorObject[]) || (verifyOfficeInfo.error as ErrorObject[]),
      );
      errorStep === "info" ? setCurrentStepIndex(0) : setCurrentStepIndex(1);
    }
  }, [verifyOfficeInfo, createOfficeInfo, setOfficeData]);

  useEffect(() => {
    if (currentStepIndex === 0 && verifyOfficeInfo.isSuccess) {
      !isLastStep && next();
      setSuccessEmail((verifyOfficeInfo.data as Info.FormInfoResponse).email);
    } else if (currentStepIndex === 1) {
      !isLastStep && next();
    } else if (currentStepIndex === 2 && createOfficeInfo.isSuccess) {
      setShowSuccesLetter(true);
      localStorage.removeItem("createForm");
    }
  }, [verifyOfficeInfo, createOfficeInfo]);

  const createOffice: Record<number, () => void> = {
    0: () => {
      const infodata: Info.FormInfo = getValueFromArray(officeData.info);
      !isError && verifyOffice(infodata);
    },
    1: () => {
      !isLastStep && next();
    },
    2: () => {
      isLastStep && createOfficeFullInfo(officeData);
    },
  };

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    setOfficeData((prev) => ({ ...prev, info: newInputs }));
    localStorage.setItem("createForm", JSON.stringify(officeData));
    createOffice[currentStepIndex]();
  };

  return (
    <div className={styles.createOffice}>
      <Helmet>
        <title>Создать нотариальную контору</title>
      </Helmet>
      <div className={cn(styles.createOfficeContainer, showSuccessLetter && styles.success, "container")}>
        <Logo className={styles.logo} />
        {showSuccessLetter ? (
          <SuccessForm />
        ) : (
          <>
            <div className={styles.formWrap}>
              <form onSubmit={onSubmit} className={styles.form}>
                <SwitchTransition>
                  <CSSTransition key={currentStepIndex + 1} timeout={300} classNames="fade">
                    {step}
                  </CSSTransition>
                </SwitchTransition>
                <div className={styles.buttons}>
                  {isFirstStep ? (
                    <Button href="/offices" variant="secondary">
                      Назад
                    </Button>
                  ) : (
                    <Button type="button" onClick={back} variant="secondary">
                      Назад
                    </Button>
                  )}
                  <Button
                    type="submit"
                    variant="primary"
                    disabled={disabled}
                    loader={createOfficeInfo.isLoading || verifyOfficeInfo.isLoading}>
                    {isLastStep ? "Создать контору" : "Дальше"}
                  </Button>
                </div>
              </form>
            </div>
            <Steps steps={menu} activeStep={currentStepIndex + 1} className={styles.steps} />
          </>
        )}
      </div>
    </div>
  );
};

export default CreateOffice;
