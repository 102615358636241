import type { FormEvent } from "react";

import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import { SwitchTransition, CSSTransition } from "react-transition-group";

import { ModalSaveData, Preloader, ErrorResult } from "@/blocks/index";
import {
  addedErrorOnField,
  checkForRussianLetters,
  isAllDaysNotAvailable,
  updateAddressInputs,
  updatedInputsData,
  updatedScheduleData,
} from "@/helpers/index";
import { Container } from "@/layout/Container";
import { useUserPermissions } from "@/store/hooks";
import { useEditOfficeInfoMutation, useFetchFullInfoQuery } from "@/store/services/office.query";
import { BackLink, Button, InputField, TimePicker, Tombler } from "@/ui/index";

import { scheduleInputsData, infoInputsData, typeOfAddress } from "./mockData";
import styles from "./OfficeInfoEdit.module.scss";

const OfficeInfoEdit = () => {
  const { id } = useParams();
  const { data, isSuccess } = useFetchFullInfoQuery(parseFloat(id || "0"));
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState<number | null>(null);
  const [inputFields, setInputFields] = useState<InputData[]>(infoInputsData);
  const [addresses, setAddress] = useState<addressType[]>(typeOfAddress);
  const [scheduleData, setScheduleData] = useState<Schedule.ScheduleItem[]>(scheduleInputsData);
  const [disabled, setDisabled] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [loading, setLoading] = useState(true);
  const pesmissions = useUserPermissions();
  const [editOfficeInfo, editOffice] = useEditOfficeInfoMutation();
  const { isError, newInputs } = checkForRussianLetters(inputFields);
  const isAllDayNotAvailable = isAllDaysNotAvailable(scheduleData);

  useEffect(() => {
    setError(pesmissions?.customUpdateNotary ? null : 403);
  }, [pesmissions?.customUpdateNotary]);

  useEffect(() => {
    if (isSuccess && data) {
      setInputFields(updatedInputsData(inputFields, data as Office.OfficeFullResponce));
      setAddress(updateAddressInputs(addresses, data as Office.OfficeFullResponce));
      setScheduleData(updatedScheduleData(scheduleData, (data as Office.OfficeFullResponce).schedule));
      setTimeout(() => {
        setLoading(false);
      }, 150);
    }
  }, [data]);

  useEffect(() => {
    setDisabled(isAllDayNotAvailable);
  }, [scheduleData]);

  const changeHandle = (
    newValue: { field: string; value: boolean | string | number; id: number | string },
    itemId: number,
  ) => {
    setInputFields([
      ...inputFields.map((item) =>
        item.field === newValue.field ? { ...item, value: newValue.value.toString(), invalid: false } : item,
      ),
    ]);
    setAddress([
      ...addresses.map((el) =>
        el.id === itemId
          ? {
              ...el,
              address: el.address.map((item) =>
                item.field === newValue.field ? { ...item, value: newValue.value.toString(), invalid: false } : item,
              ),
            }
          : el,
      ),
    ]);

    setScheduleData((prev) =>
      prev.map((item) =>
        item.id === itemId
          ? {
              ...item,
              available: newValue.field === "tombler" ? Boolean(newValue.value) : item.available,
              timeSlots: item.timeSlots.map((timeSlot) => {
                return timeSlot.id === newValue.id ? { ...timeSlot, value: newValue.value } : timeSlot;
              }),
            }
          : item,
      ),
    );
    setIsDirty(true);
  };

  useEffect(() => {
    if (editOffice.error) {
      setInputFields(addedErrorOnField(editOffice.error as ErrorObject[], inputFields));
    }
    if (editOffice.isSuccess) {
      enqueueSnackbar("Изменения сохранены!", {
        variant: "success",
        autoHideDuration: 3000,
      });
      navigate(`/offices/${id}`);
    }
  }, [editOffice]);

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    setInputFields(newInputs);
    if (id) {
      !isError && editOfficeInfo({ id, info: inputFields, schedule: scheduleData, addresses });
    }
  };

  const backToOfficeLink = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    if (isDirty) {
      setShowModal(true);
    } else {
      setShowModal(false);
      navigate(`/offices/${id}`);
    }
  };

  return (
    <div className={`${styles.editInfo} container`}>
      <Helmet>
        <title>Редактирование конторы</title>
      </Helmet>
      <SwitchTransition>
        <CSSTransition key={error ? "notFound" : "content"} timeout={250} classNames="fade">
          {error ? (
            <ErrorResult code={error} />
          ) : (
            <>
              <Container>
                <div className={styles.rowHead}>
                  <div className={styles.pageInfo}>
                    <BackLink to={`/offices/${id}`} onClick={backToOfficeLink} />
                    <h4 className={styles.mainText}>Редактирование конторы</h4>
                  </div>
                  <Button size="big" type="submit" loader={editOffice.isLoading} disabled={disabled} onClick={onSubmit}>
                    Сохранить
                  </Button>
                </div>
              </Container>
              <form className={styles.form} onSubmit={onSubmit}>
                <Preloader active={loading}>
                  <Container>
                    <div className={styles.formBottom}>
                      <div className={styles.formInfo}>
                        {inputFields.map(({ id, label, ...other }) => {
                          return (
                            <div key={id} className={styles.formRow}>
                              <label className={styles.formlabel} htmlFor={other.field}>
                                {label}
                              </label>
                              <InputField
                                className={styles.formInput}
                                id={id}
                                {...other}
                                onChange={(newVal) => changeHandle(newVal, id)}
                              />
                            </div>
                          );
                        })}
                        {addresses.map(({ address, id, label }) => {
                          return (
                            <div key={id} className={styles.formRow}>
                              <label className={styles.formlabel}>{label}</label>
                              <div className={styles.addresList}>
                                {address.map((item) => (
                                  <InputField key={item.id} {...item} onChange={(newVal) => changeHandle(newVal, id)} />
                                ))}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div className={styles.formGraphic}>
                        <label className={styles.formlabel} htmlFor="grafic">
                          График работы
                        </label>
                        <ul className={styles.formGraficList}>
                          {scheduleData.map((item) => (
                            <li className={styles.formGraficItem} key={item.id}>
                              <div className={styles.day}>{item.day}</div>
                              <Tombler
                                field="tombler"
                                label={item.available ? "Работает" : "Выходной"}
                                isChecked={item.available}
                                id={item.id}
                                onChange={(newVal) => changeHandle(newVal, item.id)}
                              />
                              {item.timeSlots.map(({ id, ...other }) => (
                                <TimePicker
                                  id={id}
                                  key={id}
                                  {...other}
                                  value={other.value}
                                  onChange={(newVal) => changeHandle(newVal, item.id)}
                                  disabled={!item.available}
                                  className={styles.scheduleItem}
                                  field={other.field}
                                />
                              ))}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </Container>
                </Preloader>
              </form>
              <ModalSaveData setShowModal={setShowModal} showModal={showModal} navigateLink={`/offices/${id}`} />
            </>
          )}
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
};

export default OfficeInfoEdit;
