import cn from "classnames";
import { memo, useEffect, useState } from "react";
import { useLocation, useNavigate, Link, useParams } from "react-router-dom";

import { ExitIcon, LogoIcon } from "@/assets/icons";
import { Profile } from "@/blocks/index";
import { getDecodedOfficeId, clearLocalStorage, getTokensFromStorage } from "@/helpers/index";
import { useActions, useAppSelector, useUser, useUserIsSystemAdmin } from "@/store/hooks";
import { useLazyGetUserQuery } from "@/store/services/auth.query";
import { useLazyFetchOfficesQuery } from "@/store/services/office.query";

import styles from "./Header.module.scss";
import { menu, menu2 } from "./mockData";
import { Notifications } from "../Notifications/Notifications";

export const Header = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { pathname } = useLocation();
  const [currentMenu, setCurretMenu] = useState<Model.MenuItem[]>([]);
  const token = getTokensFromStorage();

  const { setUser, setNewStatus } = useActions();
  const user = useUser();
  const isSystemAdmin = useUserIsSystemAdmin();
  const { hasNewStatus } = useAppSelector((state) => state.office);
  const [fetchOffices, fetchedOffices] = useLazyFetchOfficesQuery();
  const [fetchUser, fetchedUser] = useLazyGetUserQuery();

  useEffect(() => {
    if (fetchedOffices.isSuccess && fetchedOffices.data) {
      const fetch = fetchedOffices.data as Office.OfficeResponceData;

      setNewStatus(fetch.results.length > 0);
    }
  }, [fetchedOffices]);

  useEffect(() => {
    if (fetchedUser.isSuccess && fetchedUser.data) {
      setUser(fetchedUser.data as Model.User);
    }
  }, [fetchedUser]);

  useEffect(() => {
    if (isSystemAdmin) {
      setCurretMenu(menu);
      fetchOffices("?page=1&is_active=");
    }

    if (token) {
      const officeId = getDecodedOfficeId(String(token.access));
      if (officeId) {
        const updatedMenu = menu2.map((menu) => ({
          ...menu,
          path: `${menu.path}/${officeId || id}`,
        }));
        setCurretMenu(updatedMenu);
      }
      fetchUser();
    }
  }, [setCurretMenu, id, isSystemAdmin]);

  const logoutHandle = () => {
    setUser(null);
    clearLocalStorage();
    navigate("/auth");
  };

  return (
    <header className={styles.header}>
      <div className={`${styles.headerContainer} container`}>
        <Link to={currentMenu.length > 0 ? currentMenu[0].path : menu[0].path} className={styles.logo}>
          <LogoIcon />
        </Link>
        <div className={styles.buttons}>
          {currentMenu.map(({ path, title, id }) => {
            return (
              <Link
                key={id}
                className={cn(styles.button, {
                  [styles.notific]: title === "Конторы" && hasNewStatus,
                  [styles.active]: pathname.split("/").includes(path) || pathname.startsWith(`/${path}`),
                })}
                to={path}>
                {title}
              </Link>
            );
          })}
        </div>
        <div className={styles.leftSide}>
          <Notifications iconStyle={styles.icon} />
          {user && <Profile fullName={user.fullName} role={user?.role as Model.Role[]} img={user?.image} />}
          <div className={styles.icon} onClick={logoutHandle}>
            <ExitIcon className={styles.exit} />
          </div>
        </div>
      </div>
    </header>
  );
};

export default memo(Header);
