import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { baseApiUrl } from "@/api/index";
import { ApplicationService } from "@/services/ApplicationService";

export const applicationApi = createApi({
  reducerPath: "applicationApi",
  tagTypes: ["Applications", "Application", "ApplicationMembers", "ApplicationDocuments", "ApplicationMemberDocuments"],
  baseQuery: fetchBaseQuery({
    baseUrl: baseApiUrl,
  }),
  endpoints: (builder) => ({
    fetchApplications: builder.query<
      Response.ApplicationsResponse | { errors: ErrorObject[] } | { error: ErrorResponse },
      { officeId: number | string; clientId?: number; employeeId?: number; query: string }
    >({
      queryFn: async ({ officeId, clientId, employeeId, query }) => {
        try {
          const res = await ApplicationService.fetchApplications({ officeId, query, clientId, employeeId });
          return { data: res };
        } catch (error: any) {
          return {
            error: error.response.data.errors,
          };
        }
      },
      providesTags: ["Applications"],
    }),
    createApplication: builder.mutation<
      Model.Application | { errors: ErrorObject[] },
      { data: Model.Application; officeId: number | string }
    >({
      queryFn: async ({ data, officeId }) => {
        try {
          const res = await ApplicationService.createApplication({
            data,
            officeId,
          });
          return { data: res };
        } catch (error: any) {
          return {
            error: error.response.data.errors,
          };
        }
      },
      invalidatesTags: ["Applications"],
    }),
    fetchApplicationDetail: builder.query<
      Model.ApplicationDetail | { errors: ErrorObject[] } | { error: ErrorResponse },
      { officeId: number | string; applicationId: number | string }
    >({
      queryFn: async ({ officeId, applicationId }) => {
        try {
          const res = await ApplicationService.fetchApplicationDetail({ officeId, applicationId });
          return { data: res };
        } catch (error: any) {
          return {
            error: error.response.data.errors,
          };
        }
      },
      providesTags: ["Application"],
    }),
    patchApplication: builder.mutation<
      Model.ApplicationDetail | { errors: ErrorObject[] } | { error: ErrorResponse },
      {
        officeId: number | string;
        applicationId: number | string;
        data: {
          termApplication: Model.ApplicationDetail["termApplication"];
          transferPermission: Model.ApplicationDetail["transferPermission"];
        };
      }
    >({
      queryFn: async ({ officeId, applicationId, data }) => {
        try {
          const res = await ApplicationService.patchApplication({ officeId, applicationId, data });
          return { data: res };
        } catch (error: any) {
          return {
            error: error.response.data.errors,
          };
        }
      },
      invalidatesTags: ["Application"],
    }),
    fetchApplicationHistory: builder.query<
      Model.ApplicationHistory[] | { errors: ErrorObject[] },
      { officeId: number | string; applicationId: number | string }
    >({
      queryFn: async ({ officeId, applicationId }) => {
        try {
          const res = await ApplicationService.fetchApplicationHistory({ officeId, applicationId });
          return { data: res };
        } catch (error: any) {
          return {
            error: error.response.data.errors,
          };
        }
      },
      providesTags: ["Application"],
    }),
    createExecutor: builder.mutation<
      Model.Executor | { errors: ErrorObject[] },
      { data: Model.Executor; officeId: number | string; applicationId: number | string }
    >({
      queryFn: async ({ data, officeId, applicationId }) => {
        try {
          const res = await ApplicationService.createExecutor({
            data,
            officeId,
            applicationId,
          });
          return { data: res };
        } catch (error: any) {
          return {
            error: error.response.data.errors,
          };
        }
      },
      invalidatesTags: ["Application"],
    }),
    fetchExecutor: builder.query<
      Model.Executor | { errors: ErrorObject[] },
      { officeId: number | string; applicationId: number | string }
    >({
      queryFn: async ({ officeId, applicationId }) => {
        try {
          const res = await ApplicationService.fetchExecutor({ officeId, applicationId });
          return { data: res };
        } catch (error: any) {
          return {
            error: error.response.data.errors,
          };
        }
      },
    }),
    fetchApplicationClients: builder.query<
      Partial<Model.ApplicationClient>[] | { errors: ErrorObject[] } | { error: ErrorResponse },
      { officeId: number | string; applicationId: number | string }
    >({
      queryFn: async ({ officeId, applicationId }) => {
        try {
          const res = await ApplicationService.fetchApplicationClients({ officeId, applicationId });
          return { data: res };
        } catch (error: any) {
          return {
            error: error.response.data.errors,
          };
        }
      },
      providesTags: ["ApplicationMembers"],
    }),
    fetchAppicationDocumentsList: builder.query<
      string[] | { errors: ErrorObject[] },
      { officeId: number | string; applicationId: number | string }
    >({
      queryFn: async ({ officeId, applicationId }) => {
        try {
          const res = await ApplicationService.fetchAppicationDocumentsList({ officeId, applicationId });
          return { data: res };
        } catch (error: any) {
          return {
            error: error.response.data.errors,
          };
        }
      },
    }),
    updatePriority: builder.mutation<
      ResponseSuccess | { errors: ErrorObject[] },
      { priority: number; officeId: number | string; applicationId: number | string }
    >({
      queryFn: async ({ priority, officeId, applicationId }) => {
        try {
          const res = await ApplicationService.updatePriority({
            priority,
            officeId,
            applicationId,
          });
          return { data: res };
        } catch (error: any) {
          return {
            error: error.response.data.errors,
          };
        }
      },
      invalidatesTags: ["Application"],
    }),
    createApplicationClient: builder.mutation<
      Model.ApplicationClient | { errors: ErrorObject[] },
      {
        data: FormData;
        officeId: number | string;
        applicationId: number | string;
        clientId?: number | string;
        memberType: number | string;
      }
    >({
      queryFn: async ({ data, officeId, applicationId, memberType, clientId }) => {
        try {
          const res = await ApplicationService.createApplicationClient({
            data,
            officeId,
            applicationId,
            clientId,
            memberType,
          });
          return { data: res };
        } catch (error: any) {
          return {
            error: error.response.data.errors,
          };
        }
      },
      invalidatesTags: (result) => (result ? ["ApplicationMembers"] : []),
    }),
    changeApplicationClient: builder.mutation<
      Model.ApplicationClient | { errors: ErrorObject[] },
      {
        data: FormData;
        officeId: number | string;
        applicationId: number | string;
        clientId?: number | string;
        memberId: number;
      }
    >({
      queryFn: async ({ data, officeId, applicationId, clientId, memberId }) => {
        try {
          const res = await ApplicationService.changeApplicationClient({
            data,
            officeId,
            applicationId,
            clientId,
            memberId,
          });

          return { data: res };
        } catch (error: any) {
          return {
            error: error.response.data.errors,
          };
        }
      },
      invalidatesTags: (result) => (result ? ["ApplicationMembers"] : []),
    }),
    deleteApplicationClient: builder.mutation<
      Model.ApplicationClient | Model.Error,
      {
        officeId: number | string;
        applicationId: number | string;
        memberId: number;
      }
    >({
      queryFn: async ({ officeId, applicationId, memberId }) => {
        try {
          const res = await ApplicationService.deleteApplicationClient({
            officeId,
            applicationId,
            memberId,
          });

          return { data: res };
        } catch (error: any) {
          return {
            error: error.response.data,
          };
        }
      },
      invalidatesTags: ["ApplicationMembers"],
    }),
    fetchDocuments: builder.query<
      Model.FetchDocument[] | { errors: ErrorObject[] },
      { memberId: number; officeId: number | string; applicationId: number | string }
    >({
      queryFn: async ({ memberId, officeId, applicationId }) => {
        try {
          const res = await ApplicationService.fetchDocuments({ memberId, officeId, applicationId });
          return { data: res };
        } catch (error: any) {
          return {
            error: error.response.data.errors,
          };
        }
      },
      providesTags: ["ApplicationMemberDocuments"],
    }),
    downloadMemberDocuments: builder.query<
      Blob | { errors: ErrorObject[] },
      { memberId: number; officeId: number | string; applicationId: number | string }
    >({
      queryFn: async ({ memberId, officeId, applicationId }) => {
        try {
          const res = await ApplicationService.downloadMemberDocuments({ memberId, officeId, applicationId });
          return { data: res };
        } catch (error: any) {
          return {
            error: error.response.data.errors,
          };
        }
      },
      providesTags: ["ApplicationMemberDocuments"],
    }),
    fetchAppDocuments: builder.query<
      Model.FetchDocument[] | { errors: ErrorObject[] },
      { officeId: number | string; applicationId: number | string; query?: string }
    >({
      queryFn: async ({ officeId, applicationId, query }) => {
        try {
          const res = await ApplicationService.fetchDocumentsApp({ officeId, applicationId, query });

          return { data: res };
        } catch (error: any) {
          return {
            error: error.response.data.errors,
          };
        }
      },
      providesTags: ["ApplicationDocuments"],
    }),
    downloadAppDocuments: builder.query<
      Blob | { errors: ErrorObject[] },
      { officeId: number | string; applicationId: number | string; query?: string }
    >({
      queryFn: async ({ officeId, applicationId, query }) => {
        try {
          const res = await ApplicationService.downloadDocumentsApp({ officeId, applicationId, query });
          return { data: res };
        } catch (error: any) {
          return {
            error: error.response.data.errors,
          };
        }
      },
    }),
    loadDocumentsFromCard: builder.mutation<
      Model.Error | { error: Model.Error },
      {
        memberId: number;
        officeId: number | string;
        applicationId: number | string;
        documentId: number;
        documentType: number;
      }
    >({
      queryFn: async ({ memberId, officeId, applicationId, documentId, documentType }) => {
        try {
          const res = await ApplicationService.loadDocumentsFromCard({
            memberId,
            officeId,
            applicationId,
            documentId,
            documentType,
          });
          return { data: res };
        } catch (error: any) {
          return {
            error: error.response.data,
          };
        }
      },
      invalidatesTags: ["ApplicationMemberDocuments"],
    }),
    uploadDocuments: builder.mutation<
      Model.Error | { errors: ErrorObject[] },
      { memberId: number; officeId: number | string; applicationId: number | string; data: FormData; documentId?: string }
    >({
      queryFn: async ({ memberId, officeId, applicationId, data }) => {
        try {
          const res = await ApplicationService.uploadsDocuments({ memberId, officeId, applicationId, data });

          return { data: res };
        } catch (error: any) {
          return {
            error: error.response.data.errors,
          };
        }
      },
      invalidatesTags: ["ApplicationMemberDocuments"],
    }),
    uploadAppDocuments: builder.mutation<
      Model.Error | { errors: ErrorObject[] },
      { officeId: number | string; applicationId: number | string; data: FormData; query?: string; documentId?: string }
    >({
      queryFn: async ({ officeId, applicationId, data, query }) => {
        try {
          const res = await ApplicationService.uploadsDocumentsApp({ officeId, applicationId, data, query });

          return { data: res };
        } catch (error: any) {
          return {
            error: error.response.data.errors,
          };
        }
      },
      invalidatesTags: ["ApplicationDocuments"],
    }),
    deleteScan: builder.mutation<
      Model.Error | { errors: ErrorObject[] },
      { memberId: number; officeId: number | string; applicationId: number | string; scanId: number }
    >({
      queryFn: async ({ memberId, officeId, applicationId, scanId }) => {
        try {
          const res = await ApplicationService.deleteScan({ memberId, officeId, applicationId, scanId });
          return { data: res };
        } catch (error: any) {
          return {
            error: error.response.data.errors,
          };
        }
      },
      invalidatesTags: ["ApplicationMemberDocuments"],
    }),
    deleteAppScan: builder.mutation<
      Model.Error | { errors: ErrorObject[] },
      { officeId: number | string; applicationId: number | string; scanId: number }
    >({
      queryFn: async ({ officeId, applicationId, scanId }) => {
        try {
          const res = await ApplicationService.deleteScanApp({ officeId, applicationId, scanId });
          return { data: res };
        } catch (error: any) {
          return {
            error: error.response.data.errors,
          };
        }
      },
      invalidatesTags: ["ApplicationDocuments"],
    }),
    applyApplication: builder.mutation<
      Model.Error | { errors: ErrorObject[] },
      { officeId: number | string; applicationId: number | string; data: { commentary?: string; historyType: number } }
    >({
      queryFn: async ({ officeId, applicationId, data }) => {
        try {
          const res = await ApplicationService.applyApplication({ officeId, applicationId, data });
          return { data: res };
        } catch (error: any) {
          return {
            error: error.response.data,
          };
        }
      },
      invalidatesTags: ["Application"],
    }),
    changeClientMeet: builder.mutation<
      Model.ApplicationClient | { errors: ErrorObject[] },
      {
        officeId: number | string;
        applicationId: number | string;
        memberId: number | string;
      }
    >({
      queryFn: async ({ officeId, applicationId, memberId }) => {
        try {
          const res = await ApplicationService.changeClientMeet({
            officeId,
            applicationId,
            memberId,
          });

          return { data: res };
        } catch (error: any) {
          return {
            error: error.response.data.errors,
          };
        }
      },
      invalidatesTags: ["Application"],
    }),
    addAppicationClientReprasentative: builder.mutation<
      Response.MemberResponse | { errors: ErrorObject[] },
      {
        officeId: number | string;
        applicationId: number | string;
        memberId: number | string;
        representativeId: number | string;
      }
    >({
      queryFn: async ({ officeId, applicationId, memberId, representativeId }) => {
        try {
          const res = await ApplicationService.addAppicationClientReprasentative({
            officeId,
            applicationId,
            memberId,
            representativeId,
          });

          return { data: res };
        } catch (error: any) {
          return {
            error: error.response.data.errors,
          };
        }
      },
      invalidatesTags: ["ApplicationMembers"],
    }),
    getApplicationActions: builder.query<Model.Action[] | { errors: ErrorObject[] }, number | string>({
      queryFn: async (officeId) => {
        try {
          const res = await ApplicationService.getApplicationActions(officeId);
          return { data: res };
        } catch (error: any) {
          return {
            error: error.response.data.errors,
          };
        }
      },
    }),
  }),
});

export const {
  useFetchApplicationsQuery,
  useCreateApplicationMutation,
  useFetchApplicationDetailQuery,
  useFetchApplicationHistoryQuery,
  useCreateExecutorMutation,
  useFetchExecutorQuery,
  useFetchApplicationClientsQuery,
  useCreateApplicationClientMutation,
  useUpdatePriorityMutation,
  useFetchDocumentsQuery,
  useLoadDocumentsFromCardMutation,
  useUploadDocumentsMutation,
  useDeleteScanMutation,
  useApplyApplicationMutation,
  useChangeApplicationClientMutation,
  useChangeClientMeetMutation,
  useFetchAppicationDocumentsListQuery,
  useDeleteApplicationClientMutation,
  useDeleteAppScanMutation,
  useFetchAppDocumentsQuery,
  useUploadAppDocumentsMutation,
  useAddAppicationClientReprasentativeMutation,
  useGetApplicationActionsQuery,
  useLazyDownloadAppDocumentsQuery,
  useLazyDownloadMemberDocumentsQuery,
  usePatchApplicationMutation,
} = applicationApi;
