export const individualPerson: ObjectTypeGeneric<Model.Client>[] = [
  { id: 1, key: "Телефон", value: "phoneNumber" },
  { id: 2, key: "Почта", value: "email" },
  { id: 3, key: "СНИЛС", value: "snils" },
  { id: 13, key: "ИНН", value: "inn" },
  { id: 4, key: "Кем выдан паспорт", value: "passportIssuedBy" },
  { id: 5, key: "Дата выдачи паспорта", value: "passportIssueDate" },
  { id: 6, key: "Серия Номер", value: "passportSeries" },
  { id: 7, key: "Код подразделения", value: "passportCode" },
  { id: 8, key: "Адрес регистрации", value: "address" },
  { id: 9, key: "Номер конторы", value: "officeId" },
  { id: 10, key: "Законный представитель", value: "legalRepresentativeName" },
  { id: 11, key: "Место рождения", value: "placeOfBirth" },
  { id: 12, key: "Дата рождения", value: "dateOfBirth" },
  { id: 14, key: "Номер cвидетельства о рождении", value: "birthCertificateNumber" },
  { id: 18, key: "Номер актовой записи", value: "birthRegistrationNumber" },
  { id: 17, key: "Дата выдачи cвидетельства о рождении", value: "birthRegistrationDate" },
  { id: 16, key: "Орган регистрации", value: "birthIssuedBy" },
  { id: 15, key: "Дата актовой записи", value: "birthIssueDate" },
];

export const soleProprietorshipArray: ObjectTypeGeneric<Model.Client>[] = [
  { id: 1, key: "Наименование", value: "name" },
  { id: 2, key: "Телефон", value: "phoneNumber" },
  { id: 3, key: "Почта", value: "email" },
  { id: 4, key: "ИНН", value: "inn" },
  { id: 5, key: "ОГРНИП", value: "ogrn" },
  { id: 6, key: "Номер конторы", value: "officeId" },
  { id: 7, key: "Орган регистрации", value: "registrationDepartment" },
  { id: 8, key: "Дата регистрации ИП", value: "registrationDate" },
  { id: 9, key: "Физическое лицо", value: "linkedPersonName" },
];

export const legalPerson: ObjectTypeGeneric<Model.Client>[] = [
  { id: 1, key: "Наименование", value: "name" },
  { id: 2, key: "Организационно-правовая форма", value: "legalFormName" },
  { id: 4, key: "Телефон", value: "phoneNumber" },
  { id: 5, key: "Почта", value: "email" },
  { id: 8, key: "ИНН", value: "inn" },
  { id: 3, key: "ОГРН", value: "ogrn" },
  { id: 10, key: "КПП", value: "kpp" },
  { id: 6, key: "Адрес", value: "address" },
  { id: 7, key: "Адрес ФИАС", value: "addressFias" },
  { id: 9, key: "Номер конторы", value: "officeId" },
];

export const filtersArray = [
  { value: "applications", name: "Заявки клиента", link: "", count: 0 },
  { value: "scans", name: "Сканы", link: "" },
  { value: "cards", name: "Связанные карточки ", link: "" },
  { value: "documents", name: "Учредительные документы", link: "" },
  { value: "members", name: "Представители", link: "" },
];
