import type { FC, FormEvent } from "react";

import cn from "classnames";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { DownloadIcon } from "@/assets/icons";
import { downloadRequest } from "@/helpers/downloadRequest";
import {
  useDeleteScanMutation,
  useFetchDocumentsQuery,
  useLoadDocumentsFromCardMutation,
  useUploadDocumentsMutation,
  useLazyDownloadMemberDocumentsQuery,
} from "@/store/services/application.query";
import { Button, UploadFromCard, Uploader, UploaderFiles, Textarea, DocumentsGroupName } from "@/ui/index";

import styles from "./LoadDocuments.module.scss";

const LoadDocuments: FC<UI.LoadDocuments> = ({ memberId, isEdit, name, downloadAll = true }) => {
  const { officeId, applicationId } = useParams();
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [documents, setDocuments] = useState<Model.FetchDocument[] | null>(null);

  const fetchDocuments = useFetchDocumentsQuery({
    memberId,
    officeId: Number(officeId),
    applicationId: Number(applicationId),
  });
  const [loadDocumentsFromCard, loadedDocumentsFromCard] = useLoadDocumentsFromCardMutation();
  const [uploadDocuments, uploadedDocuments] = useUploadDocumentsMutation();
  const [deleteScan, deletedScan] = useDeleteScanMutation();
  const [downloadDocumnets, downloadedDocumnets] = useLazyDownloadMemberDocumentsQuery();

  useEffect(() => {
    if (fetchDocuments && fetchDocuments.isSuccess && fetchDocuments.data) {
      setDocuments(fetchDocuments.data as Model.FetchDocument[]);
    }
  }, [fetchDocuments]);

  useEffect(() => {
    if (deletedScan.isSuccess && deletedScan.data) {
      enqueueSnackbar("Запрос прошел успешно!", {
        variant: "success",
        autoHideDuration: 3000,
        description: "Фото профиля успешно удалено!",
      });
    }
  }, [deletedScan]);

  useEffect(() => {
    if (downloadedDocumnets.isSuccess && downloadedDocumnets.data) {
      downloadRequest(downloadedDocumnets.data as Blob, name);
    }

    if (downloadedDocumnets.isError && downloadedDocumnets.error) {
      let error = "При выполнении запроса что-то пошло не так..";
      if ((downloadedDocumnets.error as Model.Error)?.message) {
        error = (downloadedDocumnets.error as Model.Error).message;
      } else if ((downloadedDocumnets.error as ErrorSimpleResponse)?.errors) {
        error = (downloadedDocumnets.error as ErrorSimpleResponse).errors[0]?.detail;
      } else if (downloadedDocumnets.error as ErrorObject[]) {
        error = (downloadedDocumnets.error as ErrorObject[])[0].detail;
      }

      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: 4000,
      });
    }
  }, [downloadedDocumnets]);

  useEffect(() => {
    if (uploadedDocuments.isSuccess && uploadedDocuments.data) {
      enqueueSnackbar("Запрос прошел успешно!", {
        variant: "success",
        autoHideDuration: 3000,
      });
    }

    if (uploadedDocuments.isError && (uploadedDocuments.error as ErrorObject[])) {
      let error = "При выполнении запроса что-то пошло не так..";
      if ((uploadedDocuments.error as Model.Error)?.message) {
        error = (uploadedDocuments.error as Model.Error).message;
      } else if ((uploadedDocuments.error as ErrorSimpleResponse)?.errors) {
        error = (uploadedDocuments.error as ErrorSimpleResponse).errors[0]?.detail;
      } else if (uploadedDocuments.error as ErrorObject[]) {
        error = (uploadedDocuments.error as ErrorObject[])[0].detail;
      }

      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: 4000,
      });
    }
  }, [uploadedDocuments]);

  useEffect(() => {
    if (loadedDocumentsFromCard.isSuccess && loadedDocumentsFromCard.data) {
      enqueueSnackbar("Запрос прошел успешно!", {
        variant: "success",
        autoHideDuration: 3000,
      });
    }

    if (loadedDocumentsFromCard.isError && loadedDocumentsFromCard.error) {
      let error = "При выполнении запроса что-то пошло не так..";
      if ((loadedDocumentsFromCard.error as Model.Error)?.message) {
        error = (loadedDocumentsFromCard.error as Model.Error).message;
      } else if ((loadedDocumentsFromCard.error as ErrorSimpleResponse)?.errors) {
        error = (loadedDocumentsFromCard.error as ErrorSimpleResponse).errors[0]?.detail;
      } else if (loadedDocumentsFromCard.error as ErrorObject[]) {
        error = (loadedDocumentsFromCard.error as ErrorObject[])[0].detail;
      }

      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: 4000,
      });
    }
  }, [loadedDocumentsFromCard]);

  const addDocumentsFromCard = (documentId: number, documentType: number) => {
    loadDocumentsFromCard({
      officeId: Number(officeId),
      applicationId: Number(applicationId),
      memberId,
      documentId,
      documentType,
    });
  };

  const handleClick = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const handleFileChange = (event: FormEvent<HTMLFormElement> | null, documentId: string, data?: File[]) => {
    if (event) {
      event.preventDefault();
    }
    const formData = new FormData();
    if (data) {
      data.forEach((file) => {
        formData.append("scanners", file);
      });
    } else if (event) {
      const elem = event.target; // @ts-ignore
      const value = elem.querySelector("textarea[name='description']")?.value || "";
      formData.append("description", value);
    }
    formData.append("document", documentId);

    uploadDocuments({
      officeId: Number(officeId),
      applicationId: Number(applicationId),
      memberId,
      data: formData,
      documentId,
    });
  };

  const deleteScanHandle = (scanId: number) => {
    deleteScan({ officeId: Number(officeId), applicationId: Number(applicationId), memberId, scanId });
  };

  const downloadHandle = () => {
    downloadDocumnets({
      memberId,
      officeId: Number(officeId),
      applicationId: Number(applicationId),
    });
  };

  return (
    <div className={styles.loadDocuments}>
      <h5>
        Документы
        {documents && documents.length > 0 && downloadAll && (
          <Button
            variant="secondary"
            type="button"
            size="small"
            onClick={downloadHandle}
            disabled={downloadedDocumnets.isFetching}>
            <DownloadIcon /> Скачать все
          </Button>
        )}
      </h5>
      <div className={styles.accordionWrap}>
        {documents &&
          documents.length > 0 &&
          documents.map((el, docIndex) => {
            return (
              <div className={styles.accordion} key={el.id}>
                <div className={styles.accordionbutton}>
                  <DocumentsGroupName
                    name={el.name}
                    required={el.required}
                    length={el.scans.length}
                    onClick={() => handleClick(docIndex)}
                    showArrow={activeIndex === docIndex}
                  />
                </div>
                {!isEdit && el.withDescription && el.description && (
                  <div className={styles.description}>
                    <div className={styles.descriptionTitle}>Описание</div>
                    {el.description.trim().length > 0 ? (
                      <div>{el.description}</div>
                    ) : (
                      <div className={styles.empty}>Описание отсутствует</div>
                    )}
                  </div>
                )}
                <div
                  className={cn(
                    styles.accordionContent,
                    activeIndex === docIndex || el.scans.length === 0 ? styles.show : styles.hide,
                  )}>
                  <div className={styles.uploadFiles}>
                    <UploaderFiles files={el.scans} deleteHandle={isEdit ? deleteScanHandle : undefined} />
                  </div>
                  {isEdit && (
                    <div className={cn(styles.uploadButtons, { [styles.withDesc]: el.withDescription })}>
                      <Uploader
                        multiple={!el.withDescription}
                        size="lg"
                        onChange={(data) => handleFileChange(null, String(el.id), data)}
                        isSuccess={uploadedDocuments.isSuccess}
                        isError={uploadedDocuments.isError}
                        isLoading={
                          uploadedDocuments.isLoading && uploadedDocuments.originalArgs?.documentId === String(el.id)
                        }
                        accept=".pdf, .doc, .docx, image/*"
                        text={el.withDescription ? "Загрузить документ" : undefined}
                      />
                      {el.withDescription ? (
                        <form
                          onSubmit={(e) => handleFileChange(e, String(el.id))}
                          className={styles.form}
                          autoComplete="off">
                          <div className={styles.text}>или</div>
                          <Textarea
                            id={1}
                            value={el?.description || ""}
                            field="description"
                            placeholder="Описание"
                            required
                            // onChange={(val: any) => setDescription(val.value)}
                            className={styles.input}
                          />
                          <Button type="submit" variant="primary">
                            Сохранить
                          </Button>
                        </form>
                      ) : (
                        <UploadFromCard
                          size="lg"
                          onClick={() => addDocumentsFromCard(el.id, el.documentType)}
                          isSuccess={false}
                          isLoading={false}
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default LoadDocuments;
