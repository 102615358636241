export { default as Sort } from "@/blocks/Sort/Sort";
export { default as Search } from "@/blocks/Search/Search";
export { default as Profile } from "@/blocks/Profile/Profile";
export { default as OfficeCard } from "@/blocks/OfficeCard/OfficeCard";
export { HeadOffice, HeadOfficeSkeleton } from "@/blocks/HeadOffice";
export { default as EmployeeCard } from "@/blocks/EmployeeCard/EmployeeCard";
export { default as EmptyState } from "@/blocks/EmptyState/EmptyState";
export { default as Applications } from "@/blocks/Applications/Applications";
export { default as OfficeDetailInfo } from "@/blocks/OfficeDetailInfo/OfficeDetailInfo";
export { default as Employees } from "@/blocks/Employees/Employees";
export { default as Clients } from "@/blocks/Clients/Clients";
export { default as Info } from "@/blocks/Info/Info";
export { default as EmptyResult } from "@/blocks/EmptyResult/EmptyResult";
export { default as ErrorFallback } from "@/blocks/ErrorFallback/ErrorFallback";
export { default as ListHead } from "@/blocks/ListHead/ListHead";
export { default as EmployeeForm } from "@/blocks/EmployeeForm/EmployeeForm";
export { DetailHead, DetailHeadSkeleton } from "@/blocks/DetailHead";
export { default as EmployeeInfo } from "@/blocks/EmployeeInfo/EmployeeInfo";
export { default as ErrorResult } from "@/blocks/ErrorResult/ErrorResult";
export { default as EmployeeModal } from "@/blocks/EmployeeModal/EmployeeModal";
export { default as ModalSaveData } from "@/blocks/ModalSaveData/ModalSaveData";
export { default as AddressBlock } from "@/blocks/AddressBlock/AddressBlock";
export { default as ClientCard } from "@/blocks/ClientCard/ClientCard";
export { default as AddEditClientForm } from "@/blocks/AddEditClientForm/AddEditClientForm";
export { default as AddDocumentOrMemberForm } from "@/blocks/AddDocumentOrMemberForm/AddDocumentOrMemberForm";
export { default as TitleWithIcon } from "@/blocks/TitleWithIcon/TitleWithIcon";
export { LinkCards, LinkCardsModal } from "@/blocks/LinkCards";
export { default as Scans } from "@/blocks/Scans/Scans";
export { default as Documents } from "@/blocks/Documents/Documents";
export { default as SearchWithSuggestions } from "@/blocks/SearchWithSuggestions/SearchWithSuggestions";
export { default as ClientCardSimple } from "@/blocks/ClientCard/ClientCardSimple";
export { default as Members } from "@/blocks/Members/Members";
export { default as DocumentCard } from "@/blocks/Documents/DocumentCard";
export { default as Preloader } from "@/blocks/Preloader/Preloader";
export { default as UpdateClient } from "@/blocks/UpdateClient/UpdateClient";
export { default as ActionLock } from "@/blocks/ActionLock/ActionLock";
export { default as ApplicationCard } from "@/blocks/ApplicationCard/ApplicationCard";
export { default as ApplicationList } from "@/blocks/ApplicationList/ApplicationList";
export { default as ApplicationHead } from "@/blocks/ApplicationHead/ApplicationHead";
export { default as ClientBlick } from "@/blocks/ClientBlick/ClientBlick";
export { default as HistoryBlock } from "@/blocks/HistoryBlock/HistoryBlock";
export { default as ActionIcon } from "@/blocks/ActionIcon/ActionIcon";
export { default as LoadDocuments } from "@/blocks/LoadDocuments/LoadDocuments";
export { default as AssignExecutor } from "@/blocks/AssignExecutor/AssignExecutor";
export { default as ApplicationStatus } from "@/blocks/ApplicationStatus/ApplicationStatus";
export { default as ApplicationClientForm } from "@/blocks/ApplicationClientForm/ApplicationClientForm";
export { default as ClientMeet } from "@/blocks/ClientMeet/ClientMeet";
export { default as SearchClientByPhone } from "@/blocks/SearchClientByPhone/SearchClientByPhone";
export { default as CommentBlock } from "@/blocks/CommentBlock/CommentBlock";
export { default as CancelApplication } from "@/blocks/CancelApplication/CancelApplication";
export { default as ActionCard } from "@/blocks/ActionCard/ActionCard";
export { default as ApplicationDocumentsList } from "@/blocks/ApplicationDocumentsList/ApplicationDocumentsList";
export { ApplicationDocumnets } from "@/blocks/ApplicationDocumnets/ApplicationDocumnets";
export { DocumentsForActions } from "@/blocks/DocumentsForActions";
export { ApplicationRepresentativeForm } from "./ApplicationRepresentativeForm/ApplicationRepresentativeForm";
export { ApplicationLegalRepresentativeForm } from "./ApplicationRepresentativeForm/ApplicationLegalRepresentativeForm";
export { RolePermissions } from "./RolePermissions/RolePermissions";
export { ApplicationRights } from "./ApplicationRights/ApplicationRights";
